<script setup lang="ts">
</script>

<template>
  <div class="grid min-h-screen grid-cols-[256px,auto] bg-gray-100">
    <aside
      class="sticky top-0 z-0 h-screen bg-white px-4 py-5 drop-shadow"
    >
      <div class="flex h-full flex-col gap-5">
        <header>
          <HeaderUser />
        </header>
        <nav class="flex flex-col gap-6">
          <div>
            <DTNavListHeading class="text-xs">
              Docue Technologies Oy
            </DTNavListHeading>
            <DTNavList>
              <DTNavItem
                class="relative"
                component-class="py-1.5 rounded text-sm text-gray-800"
                to="/sales"
              >
                <DTNavItemIcon>
                  <IconCoinsStacked class="text-gray-600" />
                </DTNavItemIcon>
                Sales
              </DTNavItem>
            </DTNavList>
          </div>
        </nav>
      </div>
    </aside>
    <slot />
  </div>
</template>
