<script setup lang="ts">
const { user, logout } = useAuth()
</script>

<template>
  <div class="flex h-8 items-center">
    <div
      v-if="user"
      class="flex flex-1 items-center justify-between text-sm"
    >
      <span class="break-all">
        {{ user.email }}
      </span>
      <DTButton
        size="xs"
        class="ml-1"
        variant="link"
        @click="logout"
      >
        Logout
      </DTButton>
    </div>
    <DTLoaderDots v-else />
  </div>
</template>
